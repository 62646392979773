import React, {useEffect, useState} from 'react';


import './paymentHistory.css';
import {Carousel} from "primereact/carousel";
import {Link} from "react-router-dom";
import caruselImg from "../../../../images/user/userDashboard/Yandex direct copy (4) 2.png";
import PaymentHistoryTable from "./PaymentHistoryTable";
import PaymentHistoryPagination from "./PaymentHistoryPagination";
import axios from "axios";
import {ip} from "../../../../ip";
import {storage} from "../../../../services";

const PaymentHistory = () => {
    const token = storage.local.get("token");
    const [paymentPaginationLimit, setpaymentPaginationLimit] = useState(7);
    const [paymentPaginationCurrent, setpaymentPaginationCurrent] = useState(1);

    const paymentPaginationOnchange = (e = 1, option) => {
        setpaymentPaginationCurrent(e)
        setpaymentPaginationLimit(option)
    }

const [paymentData , setPaymentData] = useState([])
    useEffect(() => {
        axios.get(`${ip}/api/v1/payment/getMyTrans`,
            {headers:{'Authorization': `Bearer ${token}`}})
            .then((res)=>{
                console.log(res);
                const newData = res?.data?.item?.map((item, index) => (
                    {
                        ...item,
                        key: index + 1,
                        amount : item.amount,
                        cardNumber : item.cardNumber,
                        payTime : item.payTime,
                        status : item.status,
                        id : item.id,
                    }
                ))
                setPaymentData(newData);
            });
    }, [])

    return (
        <div className="payment_history">
            <div className="payment_history_header">
                <h2>TO’LOV TARIXI</h2>
            </div>
            <div className="payment_history_body">
                <div className="payment_history_body_table">
                    <PaymentHistoryTable
                        data={paymentData}
                    />
                    <div className="payment_history_body_pagination">
                        {/*<PaymentHistoryPagination*/}
                        {/*    paymentPaginationLimit={paymentPaginationLimit}*/}
                        {/*    paymentPaginationCurrent={paymentPaginationCurrent}*/}
                        {/*    paymentPaginationOnchange={paymentPaginationOnchange}*/}
                        {/*    paymentTotal={paymentTotal}*/}
                        {/*/>*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentHistory;