import React, {useEffect, useState} from 'react';
import {Carousel} from 'primereact/carousel';
import {Button} from 'primereact/button';
import {Tag} from 'primereact/tag';
import Layout from '../../Layout';
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";


import youtubeIcon from '../../../../images/user/userDashboard/Vector.png';
import instaIcon from '../../../../images/user/userDashboard/Vector (49).png';
import facebookIcon from '../../../../images/user/userDashboard/Facebook (1).png';
import telegramIcon from '../../../../images/user/userDashboard/Vector (50).png';
import message2Icon from '../../../../images/user/userDashboard/Vector (51).png';
import messageIcon from '../../../../images/user/userDashboard/Message 21.png';
import groupIcon from '../../../../images/user/userDashboard/Vector (52).png';
import videoIcon from '../../../../images/user/userDashboard/Vector (53).png';
import tvIcon from '../../../../images/user/userDashboard/Vector (54).png';
import videoplayIcon from '../../../../images/user/userDashboard/videoplayIcon.png';
import caruselImg from '../../../../images/user/userDashboard/Yandex direct copy (4) 2.png';

import axios from "axios";
import {ip} from "../../../../ip";
import './userDashboard.css';
import {Collapse} from "antd";
import {storage} from "../../../../services";
import Video from 'next-video';
import ReactPlayer from "react-player/lazy";



const UserDashboard = (props) => {
    const token = storage.local.get("token");
    const user = storage.local.get("user");

    const [products, setProducts] = useState([]);

    const [myCourses, setMyCourses] = useState([]);
    const [courses, setCourses] = useState([]);
    const [statistics, setStatistics] = useState(null);
    const [subscription, setSubscription] = useState(null);

    const [courseToDoList, setCourseToDoList] = useState([]);

    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 4,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 4,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '576px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    const getIdCarousel = (id, isAuthorshipCourse) => {
        axios.get(`${ip}/api/v1/course/getCourse?courseId=${id}&isAuthorshipCourse=${isAuthorshipCourse}`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                setCourseToDoList(res?.data?.item);
            }).catch((err)=>{})
    }


    const productTemplate = (product) => {
        return (
            <div className="carousel_card" onClick={() => getIdCarousel(product?.id, product?.isAuthorshipCourse)}>
                <img src={`${ip}/api/photo/show/${product?.coverPhotoId}`}/>
            </div>
        );
    };


    useEffect(() => {
        axios.get(`${ip}/api/v1/course/getMyCourses`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                // console.log(res);
                setProducts(res?.data?.item);
            })
            .catch((error)=>{

            })
        axios.get(`${ip}/api/v1/course/getCourse?courseId=1&isAuthorshipCourse=false`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                setCourses(res?.data?.item);
            })
            .catch((error)=>{

            })
        axios.get(`${ip}/api/statistics/get`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                setStatistics(res?.data?.item);
            })
            .catch((error)=>{

            })
        axios.get(`${ip}/api/v1/subscription/exp-time`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                setSubscription(res?.data?.item);
            })
            .catch((error)=>{

            })
    }, []);

    const [videoId, setVideoId] = useState(null);
    const getVideoId = (id) => {
        setVideoId(id);
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
    };
    return (
        <div className="user_dashboard">
            <div className="user_dashboard_header">
                <h2>SIZDAGI MAVJUD KURSLAR</h2>
            </div>
            <div className="user_dashboard_body">
                <div className="user_dashboard_body_inner">
                    <div className="user_dashboard_body_inner_bloc">
                        {
                            products.length > 0 ?
                            <Carousel value={products} numVisible={5} numScroll={2}
                                      responsiveOptions={responsiveOptions} itemTemplate={productTemplate}/>
                                      :
                                <p>Xarid qilingan kurslar mavjud emas</p>
                        }
                    </div>
                    <div className="user_dashboard_body_inner_bloc2">
                        <div className="user_dashboard_body_inner_bloc2_inner">
                            <div className="user_dashboard_body_inner_bloc2_inner_inner">
                                <div className="user_dashboard_body_inner_bloc2_inner_inner_link">
                                    <h2>PRO DIGITAL</h2>
                                    <ul>
                                        <li><a href={`https://www.youtube.com/@prodigitaluz`} target="_blank"><span><img
                                            src={youtubeIcon}/><span>YOU TUBE</span></span></a></li>
                                        <li><a href={`https://www.instagram.com/prodigitaluzb/`}
                                               target="_blank"><span><img src={instaIcon}/><span>INSTAGRAM</span></span></a>
                                        </li>
                                        <li><a href={`https://www.facebook.com/prodigitalcourse.org`}
                                               target="_blank"><span><img
                                            src={facebookIcon}/><span>FACEBOOK</span></span></a></li>
                                        <li><a href={`https://t.me/prodigitaluz`} target="_blank"><span><img
                                            src={telegramIcon}/><span>TELEGRAM</span></span></a></li>
                                    </ul>
                                </div>
                                <div className="user_dashboard_body_inner_bloc2_inner_inner_link">
                                    <h2>SUPPORT</h2>
                                    <ul>
                                        <li><a href={`https://t.me/prodigital_group`} target="_blank"><span><img
                                            src={message2Icon}/><span>UMUMIY GURUH</span></span></a></li>
                                        <li><a href={`https://t.me/+g8k74Mlh8B04YjNi`} target="_blank"><span><img
                                            src={messageIcon}/><span>YOPIQ GURUH</span></span></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="user_dashboard_body_inner_bloc2_inner_inner">
                                <div className="user_dashboard_body_inner_bloc2_inner_inner_link">
                                    <h2>PLATFORMADAGI</h2>
                                    <ul>
                                        <li><a><span><img
                                            src={groupIcon}/><span>FOYDALANUVCHILAR</span></span><span>{statistics?.userCount}</span></a>
                                        </li>
                                        <li><a><span><img src={videoIcon}/><span>VIDEO DARSLIKLAR</span></span>
                                            <span>{statistics?.lessonCount}</span></a></li>
                                        <li><a><span><img src={tvIcon}/><span>KURSLAR</span></span>
                                            <span>{statistics?.courseCount}</span></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="user_dashboard_body_inner_bloc_inner2">
                            <div className="user_dashboard_body_inner_bloc2_inner2_inner">
                                <div className="user_dashboard_body_inner_bloc2_inner2_inner_collapse">
                                    <h2>{courseToDoList?.courseName}</h2>
                                    {courseToDoList?.topicDtoList?.map((item, index) => {
                                        return (
                                            <Collapse key={index}
                                                      items={[
                                                          {
                                                              key: item?.id,
                                                              label: item?.name,
                                                              children:
                                                                  <ul>
                                                                      {
                                                                          item?.lessonList?.map((x, y) => {
                                                                              return (
                                                                                  <li key={y}
                                                                                      onClick={() => getVideoId(x?.videoId)}>{x?.name}</li>
                                                                              )
                                                                          })
                                                                      }
                                                                  </ul>
                                                          },
                                                      ]}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="user_dashboard_body_inner_bloc2_inner2_inner">
                                {
                                    videoId !== null ?
                                        <div className="user_dashboard_body_inner_bloc2_inner2_inner_video_true" onContextMenu={handleContextMenu}>
                                                <ReactPlayer url={`${ip}/api/v2/video/stream/${videoId}`}
                                                             className={"react-player"}
                                                             controls
                                                             width={"100%"}
                                                             height={"100%"}
                                                             playing={true}
                                                             loop={true}
                                                             config={{ file: { attributes: { controlsList: 'nodownload' } } }}

                                                />
                                        </div>
                                        :
                                        <div className="user_dashboard_body_inner_bloc2_inner2_inner_video">
                                                <div className="user_dashboard_body_inner_bloc2_inner2_inner_video_icon">
                                                    <img src={videoplayIcon}/>
                                                </div>
                                        </div>
                                }
                                <div className="user_dashboard_body_inner_bloc2_inner2_inner_notif">
                                    <p>OBUNA TUGASHIGA <span>{subscription}</span> KUN QOLDI</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserDashboard;