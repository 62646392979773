import React, {useEffect, useMemo, useState} from 'react';

import {BrowserRouter, Link} from 'react-router-dom';
import useWindowDimensions from '../../hooks/hooks';
import {Alert, Menu, message, Popconfirm} from "antd";
import {Radio} from 'antd';
import {Button, Popover} from 'antd';

import './layout.css';
import RootPage from "../../pages/root";
import hamkorlik from "../../images/user/Vector (46).png";
import logo from '../../images/user/icon copy (3) 2.png';
import sertificat from "../../images/user/Vector (45).png";
import headerSetting from "../../images/user/Vector (38).png";
import smile from "../../images/user/🦆 icon _user smile line_.png";
import {storage} from "../../services";
import {useDispatch, useSelector} from "react-redux";
import {changeAvatar, getMeAction, LoginFailure, loginResponse, LoginSuccess} from "../../redux/action/action";
import links from "../../images/user/Vector (42).png";
import files from "../../images/user/Vector (43).png";
import dashboard from "../../images/user/Vector (39).png";
import videoIcon from "../../images/user/Vector (40).png";
import history from "../../images/user/Vector (41).png";
import books from "../../images/user/Vector (44).png";
import settings from "../../images/user/Vector (47).png";
import question from "../../images/user/Group 14290.png";
import backImg from "../../images/user/userDashboard/back2.png";
import backImg2 from "../../images/user/userDashboard/back.png";
import backImg3 from "../../images/user/userDashboard/fon2.png";
import backImg4 from "../../images/user/userDashboard/fon3.png";
import backImg5 from "../../images/user/userDashboard/fon4.png";
import mobileNotif from '../../images/user/Vector (59).png';
import mobileSetting from '../../images/user/Vector (58).png';
import mobileUser from '../../images/user/Vector (57).png';
import mobileFon from '../../images/user/Group 14285.png';
import axios from "axios";
import {ip} from "../../ip";

const Layout = (props) => {
    const [pathName, setPathName] = useState('');
    const token = storage.local.get("token");
    const user = storage.local.get("user");
    const {isAuthenticated} = useSelector(store => store.auth)
    const dispatch = useDispatch();

    const logOut = () => {
        dispatch(LoginFailure());
        // dispatch(changeAvatar('/'))
        // window.location.href = '/';
    }
    const [openLogOut, setOpenLogOut] = useState(false);
    const [openLogOutMobile, setOpenLogOutMobile] = useState(false);
    const confirm = (e) => {
        setOpenLogOut(false)
        setOpenLogOutMobile(false)
        logOut();
    };
    const handleOpenChangeLogOut = (newOpen) => {
        setOpenLogOut(newOpen);
    };
    const handleOpenChangeLogOutMobile = (newOpen) => {
        setOpenLogOutMobile(newOpen);
    };


    const storedValue = localStorage.getItem('myValue');
    const [valueRadio, setValueRadio] = useState(storedValue || 4);
    const onChangeRadio = (e) => {
        const newValue = e.target.value;
        setValueRadio(newValue);
        localStorage.setItem('myValue', newValue);
    };

    const [notifCount, setNotifCount] = useState(null);
    const [notifData, setNotifData] = useState([]);
    useEffect(() => {
        axios.get(`${ip}/api/notification/count`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                setNotifCount(res?.data);
            });


    }, []);

    const [open, setOpen] = useState(false);
    const hide = () => {
        // setOpen(false);
        axios.delete(`${ip}/api/notification/clearAll`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                axios.get(`${ip}/api/notification/getAll`,
                    {headers: {'Authorization': `Bearer ${token}`}}
                )
                    .then((res) => {
                        // console.log(res);
                        setNotifData(res?.data);
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                axios.get(`${ip}/api/notification/readAll`,
                    {headers: {'Authorization': `Bearer ${token}`}}
                )
                    .then((res) => {
                        // console.log(res);
                    })
                    .catch((error) => {

                    })
                axios.get(`${ip}/api/notification/count`,
                    {headers: {'Authorization': `Bearer ${token}`}}
                )
                    .then((res) => {
                        setNotifCount(res?.data);
                    })
                    .catch((error) => {

                    })
            });
    };

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
        axios.get(`${ip}/api/notification/getAll`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                // console.log(res);
                setNotifData(res?.data);
            })
            .catch((error) => {

            })
        axios.get(`${ip}/api/notification/readAll`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                // console.log(res);
            })
            .catch((error) => {

            })
        axios.get(`${ip}/api/notification/count`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                setNotifCount(res?.data);
            })
            .catch((error) => {

            })
    };


    const [openMobile, setOpenMobile] = useState(false);
    const hideMobile = () => {
        // setOpenMobile(false);
        axios.delete(`${ip}/api/notification/clearAll`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                axios.get(`${ip}/api/notification/getAll`,
                    {headers: {'Authorization': `Bearer ${token}`}}
                )
                    .then((res) => {
                        // console.log(res);
                        setNotifData(res?.data);
                    })
                    .catch((error) => {

                    })
                axios.get(`${ip}/api/notification/readAll`,
                    {headers: {'Authorization': `Bearer ${token}`}}
                )
                    .then((res) => {
                        // console.log(res);
                    })
                    .catch((error) => {

                    })
                axios.get(`${ip}/api/notification/count`,
                    {headers: {'Authorization': `Bearer ${token}`}}
                )
                    .then((res) => {
                        setNotifCount(res?.data);
                    })
                    .catch((error) => {

                    })
            });
    };
    const handleOpenChangeMobile = (newOpen) => {
        setOpenMobile(newOpen);
        axios.get(`${ip}/api/notification/getAll`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                // console.log(res);
                setNotifData(res?.data);
            })
            .catch((error) => {
                console.log(error)
            })
        axios.get(`${ip}/api/notification/readAll`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                // console.log(res);
            })
            .catch((error) => {
                console.log(error)
            })
        axios.get(`${ip}/api/notification/count`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                setNotifCount(res?.data);
            })
            .catch((error) => {
                console.log(error)
            })
    };

    // inspect code disabled
    const handleContextMenu = (e) => {
        // e.preventDefault();
    };

    // internet
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    useEffect(() => {
        // Event listeners for detecting online and offline status
        const handleOnline = () => {
            setIsOnline(true);
        };
        const handleOffline = () => {
            setIsOnline(false);
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);


    // internet

    return (
        <BrowserRouter>
            <div className="layout" onContextMenu={handleContextMenu}>
                <img className="layout_back_img" src={valueRadio == 1 ? backImg : valueRadio == 2 ? backImg2 :
                    valueRadio == 3 ? backImg3 : valueRadio == 4 ? backImg4 : backImg5}/>
                <div className="layout_menu">
                    <div className="layout_menu_logo">
                        <img src={logo}/>
                    </div>
                    <div className="layout_menu_body">
                        <Link to="/user/dashboard">
                            <div
                                className={pathName === "/user/dashboard" ? "layout_menu_body_link active_link" : "layout_menu_body_link"}>
                                <img src={dashboard}/><p>DASHBOARD</p></div>
                        </Link>
                        <Link to="/user/all-courses">
                            <div
                                className={pathName === "/user/all-courses" ? "layout_menu_body_link active_link" : "layout_menu_body_link"}>
                                <img src={videoIcon}/><p>BARCHA KURSLAR</p></div>
                        </Link>
                        <Link to="/user/payment-history">
                            <div
                                className={pathName === "/user/payment-history" ? "layout_menu_body_link active_link" : "layout_menu_body_link"}>
                                <img src={history}/><p>TO'LOV TARIXI</p></div>
                        </Link>
                        <Link to="/user/links">
                            <div
                                className={pathName === "/user/links" ? "layout_menu_body_link active_link" : "layout_menu_body_link"}>
                                <img src={links}/><p>LINKLAR</p></div>
                        </Link>
                        <Link to="/user/files">
                            <div
                                className={pathName === "/user/files" ? "layout_menu_body_link active_link" : "layout_menu_body_link"}>
                                <img src={files}/><p>FAYLLAR</p></div>
                        </Link>
                        <Link to="/user/library">
                            <div
                                className={pathName === "/user/library" ? "layout_menu_body_link active_link" : "layout_menu_body_link"}>
                                <img src={books}/><p>KUTUBXONA</p></div>
                        </Link>
                        <Link to="/user/certificate">
                            <div
                                className={pathName === "/user/certificate" ? "layout_menu_body_link active_link" : "layout_menu_body_link"}>
                                <img src={sertificat}/><p>SERTIFIKAT</p></div>
                        </Link>
                        <Link to="/user/cooperation">
                            <div
                                className={pathName === "/user/cooperation" ? "layout_menu_body_link active_link" : "layout_menu_body_link"}>
                                <img src={hamkorlik}/><p>HAMKORLIK</p></div>
                        </Link>
                        <Link to="/user/settings">
                            <div
                                className={pathName === "/user/settings" ? "layout_menu_body_link active_link" : "layout_menu_body_link"}>
                                <img src={settings}/><p>SOZLAMALAR</p></div>
                        </Link>
                        <Link to="/user/questions">
                            <div
                                className={pathName === "/user/questions" ? "layout_menu_body_link active_link" : "layout_menu_body_link"}>
                                <img src={question}/><p></p></div>
                        </Link>
                    </div>
                </div>

                <div className="layout_body">
                    <div className="layout_body_header">
                        <div className="header_inner">
                            <div className="header_left">
                                <h1>SALOM {user?.fullName} !</h1>
                                <h2>KURSLARNI O’ZLASHTIRISHINGIZDA OMAD TILAB QOLAMIZ</h2>
                            </div>
                            <div className="header_right">
                                <div className="header_right_setting">
                                    <div className="header_right_setting_radio">
                                        <Radio.Group onChange={onChangeRadio} value={valueRadio}
                                                     defaultValue={valueRadio}>
                                            <Radio value={1}></Radio>
                                            <Radio value={2}></Radio>
                                            <Radio value={3}></Radio>
                                            <Radio value={4}></Radio>
                                            <Radio value={5}></Radio>
                                        </Radio.Group>
                                    </div>
                                    <img src={headerSetting}/>
                                </div>
                                <Popover
                                    placement="bottomLeft"
                                    content={
                                        <div>
                                            <div className="header_right_notif_title">
                                                <p>Bildirishnomalar</p>
                                            </div>
                                            <div className="header_right_notif_body">
                                                {
                                                    notifData.length > 0 ? notifData?.reverse()?.map((item, index) => {
                                                            return (
                                                                <div className={item?.isRead ? "header_right_notif_popover read" :
                                                                        "header_right_notif_popover noRead"}>{index+1} : {item?.message}</div>
                                                            )
                                                        }) :
                                                        <div className="header_right_notif_body_clear">Bildirishnomalar
                                                            yo'q !</div>
                                                }
                                            </div>
                                            <div className="header_right_notif_popover_clear" onClick={hide}>
                                                <button>Tozalash</button>
                                            </div>
                                        </div>
                                    }
                                    trigger="click"
                                    open={open}
                                    onOpenChange={handleOpenChange}
                                >
                                    <div className="header_right_notif">
                                        <img src={mobileNotif}/>
                                        <div className="header_right_notif_text">{notifCount}</div>
                                    </div>
                                </Popover>

                                <div className="header_right_settings">
                                    <Link to="/user/settings"><img src={mobileSetting}/></Link>
                                </div>


                                <div className="header_right_logOut">
                                    <Popover
                                        placement="bottomLeft"
                                        content={
                                            <div className="log_out">
                                                <div className="log_out_text">
                                                    <h2>Tizimdan chiqish !</h2>
                                                </div>
                                                <div className="log_out_buttons">
                                                    <button onClick={() => setOpenLogOut(false)}>Bekor qilish</button>
                                                    <button onClick={confirm}>Chiqish</button>
                                                </div>
                                            </div>
                                        }
                                        trigger="click"
                                        open={openLogOut}
                                        onOpenChange={handleOpenChangeLogOut}
                                    >
                                        <div className="header_right_name">
                                            <p>{user?.username}</p>
                                            <img src={smile}/>
                                        </div>
                                    </Popover>
                                </div>


                            </div>
                        </div>
                        <div className="header_inner_mobile">
                            <div className="header_inner_mobile_top">
                                <div className="header_inner_mobile_logo"><img src={logo}/></div>
                                <div className="header_right_setting">
                                    <div className="header_right_setting_radio">
                                        <Radio.Group onChange={onChangeRadio} value={valueRadio}
                                                     defaultValue={valueRadio}>
                                            <Radio value={1}></Radio>
                                            <Radio value={2}></Radio>
                                            <Radio value={3}></Radio>
                                            <Radio value={4}></Radio>
                                            <Radio value={5}></Radio>
                                        </Radio.Group>
                                    </div>
                                    <img src={mobileFon}/>
                                </div>
                                <Popover
                                    placement="bottom"
                                    content={
                                        <div>
                                            <div className="header_right_notif_title">
                                                <p>Bildirishnomalar</p>
                                            </div>
                                            <div className="header_right_notif_body">
                                                {
                                                    notifData.length > 0 ? notifData?.map((item, index) => {
                                                            return (
                                                                <div
                                                                    className={item?.isRead ? "header_right_notif_popover read" :
                                                                        "header_right_notif_popover noRead"}>{index+1} : {item?.message}</div>
                                                            )
                                                        }) :
                                                        <div className="header_right_notif_body_clear">Bildirishnomalar
                                                            yo'q !</div>
                                                }
                                            </div>
                                            <div className="header_right_notif_popover_clear" onClick={hideMobile}>
                                                <button>Tozalash</button>
                                            </div>
                                        </div>
                                    }
                                    trigger="click"
                                    open={openMobile}
                                    onOpenChange={handleOpenChangeMobile}
                                >
                                    <div className="header_inner_mobile_icon">
                                        <img src={mobileNotif}/>
                                        <div className="header_inner_mobile_icon_notif">{notifCount}</div>
                                    </div>
                                </Popover>

                                <div className="header_inner_mobile_icon">
                                    <div className="header_inner_mobile_icon_settings">
                                        <Link to="/user/settings"><img src={mobileSetting}/></Link>
                                    </div>
                                </div>
                                <div className="header_inner_mobile_icon">
                                    <Popover
                                        placement="bottomLeft"
                                        content={
                                            <div className="log_out">
                                                <div className="log_out_text">
                                                    <h2>Tizimdan chiqish !</h2>
                                                </div>
                                                <div className="log_out_buttons">
                                                    <button onClick={() => setOpenLogOutMobile(false)}>Bekor qilish
                                                    </button>
                                                    <button onClick={confirm}>Chiqish</button>
                                                </div>
                                            </div>
                                        }
                                        trigger="click"
                                        open={openLogOutMobile}
                                        onOpenChange={handleOpenChangeLogOutMobile}
                                    >
                                        <div className="header_right_name">
                                            <img src={mobileUser}/>
                                        </div>
                                    </Popover>
                                </div>
                            </div>
                            <div className="header_inner_mobile_bottom">
                                <div className="header_left">
                                    <h1>SALOM {user?.fullName} !</h1>
                                    <h2>KURSLARNI O’ZLASHTIRISHINGIZDA OMAD TILAB QOLAMIZ</h2>
                                </div>
                            </div>
                        </div>
                        <div className="header_line"></div>
                    </div>
                    <div className="layout_body_inner">
                        <RootPage setPathName={setPathName}/>
                    </div>
                </div>
            </div>
        </BrowserRouter>
    );
};

export default Layout;