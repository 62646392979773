import React, {useRef, useEffect, useState} from 'react';
import {BrowserRouter, Link, Navigate} from "react-router-dom";
import Lottie from 'lottie-react';
import {ip} from '../../ip';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import globus from '../../images/landingImage/animation/4.7.webm';
import arrowRight from '../../images/landingImage/shape.svg';
import raket from '../../images/landingImage/Vectary texture (1).svg';
import tablet from '../../images/landingImage/Vectary texture.svg';
import motivx from '../../images/landingImage/Shapes 3D Image.svg';
import shapes from '../../images/landingImage/Shapes 3D Image (2).svg';
import people from '../../images/landingImage/people.svg';
import cube from '../../images/landingImage/3dcube.svg';
import stroke from '../../images/landingImage/Line 1 (Stroke).svg';
import groupImg from '../../images/landingImage/Group 14288.svg';
import screenshot from '../../images/landingImage/Rectangle 34.png';
import bottomVek from '../../images/landingImage/Vector (35).png';
import commingSoon from '../../images/landingImage/Sales funnels (2) 4.png';
import eye from '../../images/landingImage/eye 1.png';
import action from '../../images/landingImage/action 1.png';
import facebook from '../../images/landingImage/facebook.png';
import youtube from '../../images/landingImage/youtube.png';
import instagram from '../../images/landingImage/instagram.png';
import telegram from '../../images/landingImage/Group 14305.png';
import footerLogo from '../../images/landingImage/icon 1.png';

import globe from '../../assets/Animation - 1707586968008 (3).json';
import buttonAnim from '../../assets/buttonanim.json';
import burger from '../../images/landingImage/Hamburger-Button.png';

import CountUp from 'react-countup';

import './landingPage.css';
import './mobileRespons.css';
import {Collapse} from 'antd';

import {TEAnimation} from "tw-elements-react";
import {AnimatedOnScroll} from "react-animated-css-onscroll";
import {paste} from "@testing-library/user-event/dist/paste";

import axios from 'axios';
import {storage} from "../../services";
import ReactPlayer from "react-player/lazy";
import {Carousel} from "primereact/carousel";

import video1 from '../../videos/videoLanding/landvideo1.mp4';
import video2 from '../../videos/videoLanding/landvideo2.mp4';
import video3 from '../../videos/videoLanding/landvideo3.mp4';


const LandingPage = () => {

    const [wordsList] = useState([
        'DIGITAL',
        'SMM',
        'CHATBOT',
        'CRM',
        'FUNNELS',
        'SALES',
        'GOOGLE',
        'YANDEX',
        'TARGET',
        'SEO',
        'WEBSITE',
        'METRICS',
        'AFFILIATE',
    ]);
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentWordIndex((prevIndex) => (prevIndex + 1) % wordsList.length);
        }, 2000);
        return () => clearInterval(interval);
    }, [wordsList.length]);

    const [statistic, setStatistic] = useState([]);
    useEffect(() => {
        axios.get(`${ip}/api/statistics/get`)
            .then((res) => {
                setStatistic(res?.data?.item);
            }).catch((err) => {
        })
    }, []);

    // land more
    const [visibleDivs, setVisibleDivs] = useState(2);
    const handleLoadMore = () => {
        setVisibleDivs((prevVisibleDivs) => prevVisibleDivs + 2);
    };
    // land more

    const targetRef = useRef(null);
    const handleScroll = () => {
        const scrollPosition = window?.scrollY;
        const targetElement = targetRef?.current;
        const targetPosition = targetElement?.getBoundingClientRect()?.top + scrollPosition - 100;
        const threshold = 100;
        if (scrollPosition >= targetPosition - threshold && scrollPosition <= targetPosition + targetElement.offsetHeight + threshold) {
            yourFunction(targetPosition - threshold, targetPosition + targetElement.offsetHeight - threshold);
        }
    };

    useEffect(() => {
        window?.addEventListener('scroll', handleScroll);
        return () => {
            window?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [locat, setLocat] = useState(0);

    const yourFunction = (tepa, past) => {
        if (window?.scrollY <= past) {
            setLocat(window?.scrollY - tepa);
        } else if (window?.scrollY < tepa) {
            setLocat(0)
        }
    };


    // get functions
    const [courseAll, setCourseAll] = useState([]);
    const [screenFoto, setScreenFoto] = useState([]);
    const [resentCourse, setResentCourse] = useState([]);
    const [videoFeedback, setVideoFeedback] = useState([]);
    useEffect(() => {
        axios.get(`${ip}/api/v1/course/all-course`)
            .then((res) => {
                setCourseAll(res?.data?.item);
            }).catch((err) => {
        })
        axios.get(`${ip}/api/corusel/photo-feedback/get-all`)
            .then((res) => {
                setScreenFoto(res?.data?.item);
            }).catch((err) => {
        })
        axios.get(`${ip}/api/corusel/recent-course/all`)
            .then((res) => {
                setResentCourse(res?.data?.item);
            }).catch((err) => {
        })
        axios.get(`${ip}/api/corusel/video-feedback/get-all`)
            .then((res) => {
                setVideoFeedback(res?.data?.item);
            }).catch((err) => {
        })
    }, []);

    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 5,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 5,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '576px',
            numVisible: 1,
            numScroll: 1
        }
    ];
    const productTemplate = (courseAll) => {
        return (
            <div className="landing_page_section_5_carousel_inner_card">
                <div className="landing_page_section_5_carousel_inner_head">
                    <img src={`${ip}/api/photo/show/${courseAll?.coverPhotoId}`}/>
                </div>
                <div className="landing_page_section_5_carousel_inner_card_body">
                    <h2>{courseAll?.courseName}</h2>
                    <div className="landing_page_section_5_carousel_inner_card_body_line">
                        <div className="line"></div>
                    </div>
                    <div className="landing_page_section_5_carousel_inner_card_body_text">
                        <p>{courseAll?.description}</p>
                    </div>
                </div>
            </div>
        )
    };

    const productTemplateScreen = (screenFoto) => {
        return (
            <div className="landing_page_section_5_screen_img_inner">
                <img src={`${ip}/api/photo/show/${screenFoto?.photoId}`}/>
            </div>
        )
    };
    const [numMobileData, setNumMobileData] = useState([
        {
            card: <div className="landing_page_section_5_screen_news_number_inner">
                <div className="landing_page_section_5_screen_news_number_inner_top_1">
                    <div className="landing_page_section_5_screen_news_number_inner_top"><h2>01</h2></div>
                </div>
                <div className="landing_page_section_5_screen_news_number_inner_bottom">
                    <img src={arrowRight}/>
                    <h6>OBUNA<br/> BO’LING</h6>
                </div>
            </div>
        },
        {
            card: <div className="landing_page_section_5_screen_news_number_inner">
                <div className="landing_page_section_5_screen_news_number_inner_top_1">
                    <div className="landing_page_section_5_screen_news_number_inner_top"><h2>02</h2>
                    </div>
                </div>
                <div className="landing_page_section_5_screen_news_number_inner_bottom">
                    <img src={arrowRight}/>
                    <h6>TA’LIM <br/>OLING</h6>
                </div>
            </div>
        },
        {
            card: <div className="landing_page_section_5_screen_news_number_inner">
                <div className="landing_page_section_5_screen_news_number_inner_top_1">
                    <div className="landing_page_section_5_screen_news_number_inner_top"><h2>03</h2>
                    </div>
                </div>
                <div className="landing_page_section_5_screen_news_number_inner_bottom">
                    <img src={arrowRight}/>
                    <h6>TAJRIBA <br/>ORTTIRING</h6>
                </div>
            </div>
        },
        {
            card: <div className="landing_page_section_5_screen_news_number_inner">
                <div className="landing_page_section_5_screen_news_number_inner_top_1">
                    <div className="landing_page_section_5_screen_news_number_inner_top"><h2>04</h2>
                    </div>
                </div>
                <div className="landing_page_section_5_screen_news_number_inner_bottom">
                    <img src={arrowRight}/>
                    <h6>PROFESSIONAL<br/> BO’LING</h6>
                </div>
            </div>
        },
    ]);
    const productTemplateNum = (numMobileData) => {
        return (
            <div className="landing_page_section_5_screen_news_number_mobile_1">
                {numMobileData?.card}
            </div>
        )
    };

    const textValues = ["FOYDALANUVCHILAR", "KURSLAR", "DARSLIKLAR"];
    const totalData = Object?.entries(statistic)?.map(([key, value], index) => ({
        statistic: value,
        text: textValues[index]
    }));

    const productTemplateTotal = (totalData) => {
        return (
            <div className="landing_page_section_2_total_mobile_inner">
                <div className="landing_page_section_2_total_inner">
                    <h2>{totalData?.statistic}</h2>
                    <p>{totalData?.text}</p>
                </div>
            </div>
        )
    };

    const productTemplateResentCourse = (resentCourse) => {
        return (
            <div className="landing_page_section_5_screen_news_carousel_inner_card">
                <img src={`${ip}/api/photo/show/${resentCourse?.photoId}`}/>
            </div>
        )
    };

    return (
        <div className="landing_page">
            <div className="landing_page_header">
                <div className="landing_page_header_logo">
                    <a href='#'><h2>PRO</h2><h2>DIGITAL</h2></a>
                </div>

                <div className="landing_page_header_right">
                    <div className="landing_page_header_right_burger">
                        <Link to="/register"><img src={burger}/></Link>
                    </div>
                    <div className="landing_page_header_right_inner">
                        <div className="landing_page_header_right_links">
                            <a href="#kurslar">KURSLAR</a>
                            <a href="#tarif">TA’RIFLAR</a>
                            <a href="#aloqa">ALOQA</a>
                        </div>
                        <Link to="/register">
                            <div className="landing_page_header_right_registr">RO’YXATDAN O’TISH</div>
                        </Link>
                        <Link to="/login">
                            <div className="landing_page_header_right_login">KIRISH</div>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="landing_page_section_1">
                <div className="landing_page_section_1_left">
                    <div className="landing_page_section_1_left_logo">
                        <h1>PRO</h1><h1
                        className="landing_page_section_1_left_logo_changeText">{wordsList[currentWordIndex]}</h1>
                    </div>
                    <p>Raqamli Marketing ehtiyojlaringiz uchun Doimiy yechim.<br/>
                        Ushbu ta’lim platformasi Raqamli Marketingda ustunlik qila<br/> olishingizga va yuqori
                        natijalarga
                        erishishingizga yordam beradi.</p>
                    <div className="landing_page_section_1_left_link">
                        <Link to="/register">
                            <div className="landing_page_section_1_left_link_anim">
                                <Lottie animationData={buttonAnim}/>
                                <p>HOZIROQ BOSHLASH</p>
                            </div>
                        </Link>
                        <Link to="/register"><a href="#"><img src={arrowRight}/></a></Link>
                    </div>
                </div>
                <div className="landing_page_section_1_right">
                    <Lottie
                        animationData={globe}
                        // options={globe}
                        // height={400}
                        // width={400}
                        // isStopped={this.state.isStopped}
                        // isPaused={this.state.isPaused}
                    />
                </div>
            </div>

            <div className="landing_page_section_2">
                <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                    <div className="landing_page_section_2_total_mobile">
                        <Carousel value={totalData} numVisible={5} numScroll={3}
                                  responsiveOptions={responsiveOptions} itemTemplate={productTemplateTotal}/>
                    </div>

                    <div className="landing_page_section_2_total">
                        <div className="landing_page_section_2_total_inner">
                            <h2><CountUp start={0} end={statistic?.courseCount} duration={6} delay={3}/></h2>
                            <p>KURSLAR</p>
                        </div>
                        <div className="landing_page_section_2_total_inner">
                            <h2><CountUp start={0} end={statistic?.userCount} duration={6} delay={3}/></h2>
                            <p>FOYDALANUVCHILAR</p>
                        </div>
                        <div className="landing_page_section_2_total_inner">
                            <h2><CountUp start={0} end={statistic?.lessonCount} duration={6} delay={3}/></h2>
                            <p>DARSLIKLAR</p>
                        </div>
                    </div>
                </AnimatedOnScroll>

                <AnimatedOnScroll animationIn="zoomIn" animationInDelay="50" animationInDuration="500">
                    <div className="landing_page_section_2_motiv">
                        <div className="landing_page_section_2_motiv_inner">
                            <img src={raket}/>
                        </div>

                        <div className="landing_page_section_2_motiv_inner">
                            <img src={motivx}/>
                            <h2>TEZROQ, YAXSHIROQ,<br/> AQLLIROQ RIVOJLANING!</h2>
                        </div>
                        <div className="landing_page_section_2_motiv_inner">
                            <img src={tablet}/>

                        </div>
                    </div>
                </AnimatedOnScroll>
            </div>

            <div className="landing_page_section_3" ref={targetRef}>
                <div className="landing_page_section_3_verticalLine" style={{height: `${locat}px`}}>
                    <div className="landing_page_section_3_verticalLine_square"></div>
                </div>
                <div className='landing_page_section_3_animation' onScroll={handleScroll}>
                    <div className="landing_page_section_3_animation_inner">
                        <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                            <div className="landing_page_section_3_animation_inner1">
                                <div className="landing_page_section_3_animation_inner1_top">
                                    <img src={groupImg}/><h2>TAJRIBAGA ASOSLANGAN BILIMLAR</h2>
                                </div>
                                <div className="landing_page_section_3_animation_inner1_bottom">
                                    <p><img src={stroke}/>REAL KEYSLARGA ASOSLANGAN O'QUV DASTURlar</p>
                                    <p><img src={stroke}/>bilimingizni 10x ga oshiruvchi video darsliklar</p>
                                    <p><img src={stroke}/>amalda ishlaydigan uslubiy qo'llanmalar</p>
                                </div>
                            </div>
                        </AnimatedOnScroll>
                        <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                            <div className="landing_page_section_3_animation_inner2">
                                {/*<ReactPlayer*/}
                                {/*    url={video3}*/}
                                {/*    className={"react-player"}*/}
                                {/*    controls={true}*/}
                                {/*    width={"100%"}*/}
                                {/*    height={"100%"}*/}
                                {/*    playing={true}*/}
                                {/*    loop={true}*/}
                                {/*    config={{file: {attributes: {controlsList: 'nodownload'}}}}*/}
                                {/*/>*/}

                                <video autoPlay loop muted playsinline className="background-video" style={{width:"100%" , height:"100%"}}>
                                    <source src={video3} type="video/mp4"/>
                                </video>

                            </div>
                        </AnimatedOnScroll>
                    </div>

                    <div className="landing_page_section_3_animation_inner">
                        <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                            <div className="landing_page_section_3_animation_inner2">
                                {/*<ReactPlayer*/}
                                {/*    url={video1}*/}
                                {/*    className={"react-player"}*/}
                                {/*    controls={false}*/}
                                {/*    width={"100%"}*/}
                                {/*    height={"100%"}*/}
                                {/*    playing={true}*/}
                                {/*    loop={true}*/}
                                {/*    config={{file: {attributes: {controlsList: 'nodownload'}}}}*/}
                                {/*/>*/}
                                <video autoPlay loop muted playsinline className="background-video" style={{width:"100%" , height:"100%"}}>
                                    <source src={video1} type="video/mp4"/>
                                </video>
                            </div>
                        </AnimatedOnScroll>
                        <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                            <div className="landing_page_section_3_animation_inner1">
                                <div className="landing_page_section_3_animation_inner1_top">
                                    <img src={cube}/><h2>UNIKAL ISHLANGAN TA’LIM TIZIMI</h2>
                                </div>
                                <div className="landing_page_section_3_animation_inner1_bottom">
                                    <p><img src={stroke}/>istalgan joyda, istalgan vaqtda kurslarni ko’rish</p>
                                    <p><img src={stroke}/>yuqori standartlarga javob beradigan dastur</p>
                                    <p><img src={stroke}/>mentorlik qobiliyatlari bor mutaxassislar</p>
                                </div>
                            </div>
                        </AnimatedOnScroll>
                    </div>

                    <div className="landing_page_section_3_animation_inner">
                        <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                            <div className="landing_page_section_3_animation_inner1">
                                <div className="landing_page_section_3_animation_inner1_top">
                                    <img src={people}/><h2>DIGITAL MARKETOLOGLAR JAMIYATI</h2>
                                </div>
                                <div className="landing_page_section_3_animation_inner1_bottom">
                                    <p><img src={stroke}/>2000+ soha mutaxassislari bilan networking</p>
                                    <p><img src={stroke}/>Natijangizni hamkasblar bilan bo’lishish</p>
                                    <p><img src={stroke}/>sizdek fikrlaydiganlar muhitida bo’lish</p>
                                </div>
                            </div>
                        </AnimatedOnScroll>
                        <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                            <div className="landing_page_section_3_animation_inner2">
                                {/*<ReactPlayer*/}
                                {/*    url={video2}*/}
                                {/*    className={"react-player"}*/}
                                {/*    controls={false}*/}
                                {/*    width={"100%"}*/}
                                {/*    height={"100%"}*/}
                                {/*    playing={true}*/}
                                {/*    loop={true}*/}
                                {/*    config={{file: {attributes: {controlsList: 'nodownload'}}}}*/}
                                {/*/>*/}
                                <video autoPlay loop muted playsinline className="background-video" style={{width:"100%" , height:"100%"}}>
                                    <source src={video2} type="video/mp4"/>
                                </video>
                            </div>
                        </AnimatedOnScroll>
                    </div>
                </div>
            </div>

            <div className="landing_page_section_4">
                <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                    <div className="landing_page_section_4_top">
                        <p>Zamonaviy kasblar sizni boy qilishi mumkin, ammo o'rganish jarayoni haqida bunday deb ayta
                            olmaymiz</p>
                        <p>Tasavvur qiling, siz har bir Digital sohani alohida <span
                            style={{color: "#F00"}}>"MENTORLARDA"</span> o'rganishingiz uchun
                            <span style={{color: "#F00"}}> "MILLIONLAB"</span> pullaringizni yo'qotasiz. Ko’p hollarda
                            “Havoga sovurasiz”</p>
                        <p>PRO DIGITAL platformasi esa bunga haqiqatda <span
                            style={{color: "#0FC988"}}>OSON YECHIM</span> bo’la oladi!</p>
                    </div>
                </AnimatedOnScroll>
                <div className="landing_page_section_4_medium">
                    <AnimatedOnScroll animationIn="tada" animationInDuration="4000">
                        <img src={shapes}/>
                    </AnimatedOnScroll>
                </div>
                <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                    <div className="landing_page_section_4_bottom" id="kurslar">
                        <h2>DAROMADINGIZNI</h2>
                        <h1>{`< SHAVQATSIZLARCHA >`}</h1>
                        <p>O’STIRA OLADIGAN ENG SAMARALI KURSLAR TO’PLAMI</p>
                    </div>
                </AnimatedOnScroll>
            </div>

            <div className="landing_page_section_5_carousel">
                <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                    <Carousel value={courseAll} numVisible={5} numScroll={3}
                              responsiveOptions={responsiveOptions} itemTemplate={productTemplate}/>
                </AnimatedOnScroll>
            </div>

            <div className="landing_page_section_5_screen">
                <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                    <div className="landing_page_section_5_screen_top">
                        <h2>SIZ QANDAY HOLATDA BO’LISHINGIZNING</h2>
                        <h1>UMUMAN FARQI YO’Q</h1>
                        <p>Digital Marketingda professional bo’lasizmi yoki sohaga endi kirib kelasizmi buning umuman
                            farqi
                            yo’q.</p>
                        <p>Platformada siz uchun yangi bilimlarga to’la bo’lgan kurslar mavjudligiga aniq kafolat
                            beramiz!</p>
                    </div>
                </AnimatedOnScroll>

                <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                    <div className="landing_page_section_5_screen_img">
                        <Carousel value={screenFoto} numVisible={5} numScroll={3}
                                  responsiveOptions={responsiveOptions} itemTemplate={productTemplateScreen}/>
                    </div>
                </AnimatedOnScroll>
                <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                    <div className="landing_page_section_5_screen_subscription">
                        <div className="landing_page_section_5_screen_subscription_inner">
                            <h2>SIZ VAQTNI KESMASANGIZ VAQT SIZNI KESADI</h2>
                            <p>HOZIROQ TUGMAGA BOSING </p>
                        </div>
                    </div>

                    <div className="landing_page_section_5_screen_subscription_button">
                        <Link to="/register">
                            <div className="landing_page_section_5_screen_subscription_button_inner">OBUNA BO’LISH</div>
                        </Link>
                    </div>
                </AnimatedOnScroll>

                <div className="landing_page_section_5_screen_subscription_button_text">
                    <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                        <div className="landing_page_section_5_screen_top">
                            <h1>PRO DIGITAL</h1>
                            <p>Bu raqamli marketing bo’yicha mutaxassis bo’lishingiz uchun eng yaxshi tanlovdir. <br/>
                                Platformadagi kurslar tajribali mutaxassislar tomonidan ishlab chiqilgan va eng
                                so'nggi <br/>
                                tendentsiyalarga mos keladi.</p>
                            <p>Mutaxassislarimiz Raqamli Marketing bo’yicha karyera boshlash yoki malakangizni
                                oshirish <br/>
                                uchun barcha zarur ko'nikmalar va bilimlarni egallashga yordam beradi.</p>
                            <img src={bottomVek}/>
                        </div>
                    </AnimatedOnScroll>
                </div>
            </div>

            <div className="landing_page_section_5_screen_news">
                <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                    <div className="landing_page_section_5_screen_news_number">
                        <div className="landing_page_section_5_screen_news_number_inner">
                            <div className="landing_page_section_5_screen_news_number_inner_top"><h2>01</h2></div>
                            <div className="landing_page_section_5_screen_news_number_inner_bottom">
                                <img src={arrowRight}/>
                                <h6>OBUNA<br/> BO’LING</h6>
                            </div>
                        </div>
                        <div className="landing_page_section_5_screen_news_number_inner">
                            <div className="landing_page_section_5_screen_news_number_inner_top"><h2>02</h2></div>
                            <div className="landing_page_section_5_screen_news_number_inner_bottom">
                                <img src={arrowRight}/>
                                <h6>TA’LIM <br/>OLING</h6>
                            </div>
                        </div>
                        <div className="landing_page_section_5_screen_news_number_inner">
                            <div className="landing_page_section_5_screen_news_number_inner_top"><h2>03</h2></div>
                            <div className="landing_page_section_5_screen_news_number_inner_bottom">
                                <img src={arrowRight}/>
                                <h6>TAJRIBA <br/>ORTTIRING</h6>
                            </div>
                        </div>
                        <div className="landing_page_section_5_screen_news_number_inner">
                            <div className="landing_page_section_5_screen_news_number_inner_top"><h2>04</h2></div>
                            <div className="landing_page_section_5_screen_news_number_inner_bottom">
                                <img src={arrowRight}/>
                                <h6>PROFESSIONAL<br/> BO’LING</h6>
                            </div>
                        </div>
                    </div>
                    <div className="landing_page_section_5_screen_news_number_mobile">
                        <Carousel value={numMobileData} numVisible={5} numScroll={3}
                                  responsiveOptions={responsiveOptions} itemTemplate={productTemplateNum}/>
                    </div>
                </AnimatedOnScroll>

                <div className="landing_page_section_5_screen_news_carousel">
                    <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                        <div className="landing_page_section_5_screen_news_carousel_text">
                            <div className="landing_page_section_5_screen_top">
                                <h1>DOIMIY YANGILANISHLAR</h1>
                                <p>TEZ ORADA OCHILADIGAN KURSLAR!</p>
                            </div>
                        </div>
                    </AnimatedOnScroll>
                    <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                        <div className="landing_page_section_5_screen_news_carousel_inner">
                            <Carousel value={resentCourse} numVisible={5} numScroll={3}
                                      responsiveOptions={responsiveOptions}
                                      itemTemplate={productTemplateResentCourse}/>
                        </div>
                        <div className="landing_page_section_5_screen_news_carousel_line"></div>
                    </AnimatedOnScroll>

                </div>

            </div>

            <div className="landing_page_section_6_subscribe" id="tarif">
                <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                    <div className="landing_page_section_6_subscribe_text">
                        <h2>OBUNA BO’LISHDAN BOSHQA ILOJINGIZ YO’Q!</h2>
                        <p>HOZIROQ TIZIMGA QO’SHILING</p>
                    </div>
                </AnimatedOnScroll>

                <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                    <div className="landing_page_section_6_subscribe_cards">
                        <div className="landing_page_section_6_subscribe_cards_card">
                            <div className="landing_page_section_6_subscribe_cards_card_title">
                                <h2>SHORT TERM</h2>
                            </div>
                            <div className="landing_page_section_6_subscribe_cards_card_price">
                                <span></span>
                                <h2>347.000</h2>
                            </div>
                            <ul>
                                <li> 1 OYLIK OBUNA</li>
                                <li>O’ZLASHTIRISH 25%</li>
                                <li>UMUMIY KURSLARGA YO’LLANMA</li>
                                <li>ERKIN GRAFIKDA O’QISH</li>
                                <li>COMMUNITY CHAT</li>
                                <li>FOYDALI LINKLAR</li>
                                <li>QO’LLANMALAR</li>
                                <li>USER INTERFACE</li>
                                <li>YANGI KURSLARGA BEPUL YO’LLANMA</li>
                                <li>KUTUBXONA</li>
                            </ul>
                        </div>
                        <div className="landing_page_section_6_subscribe_cards_card">
                            <div className="landing_page_section_6_subscribe_cards_card_title">
                                <h2>MEDIUM TERM</h2>
                            </div>
                            <div className="landing_page_section_6_subscribe_cards_card_price">
                                <span>15% chegirma</span>
                                <h2>880.000</h2>
                            </div>
                            <ul>
                                <li>3 OYLIK OBUNA</li>
                                <li>O’ZLASHTIRISH 50%</li>
                                <li>UMUMIY KURSLARGA YO’LLANMA</li>
                                <li>ERKIN GRAFIKDA O’QISH</li>
                                <li>COMMUNITY CHAT</li>
                                <li>FOYDALI LINKLAR</li>
                                <li>QO’LLANMALAR</li>
                                <li>USER INTERFACE</li>
                                <li>YANGI KURSLARGA BEPUL YO’LLANMA</li>
                                <li>KUTUBXONA</li>
                            </ul>
                        </div>
                        <div className="landing_page_section_6_subscribe_cards_card">
                            <div className="landing_page_section_6_subscribe_cards_card_title">
                                <h2>LONG TERM</h2>
                            </div>
                            <div className="landing_page_section_6_subscribe_cards_card_price">
                                <span>25% chegirma</span>
                                <h2>1.560.000</h2>
                            </div>
                            <ul>
                                <li>6 OYLIK OBUNA</li>
                                <li>O’ZLASHTIRISH 75%</li>
                                <li>UMUMIY KURSLARGA YO’LLANMA</li>
                                <li>ERKIN GRAFIKDA O’QISH</li>
                                <li>COMMUNITY CHAT</li>
                                <li>FOYDALI LINKLAR</li>
                                <li>QO’LLANMALAR</li>
                                <li>USER INTERFACE</li>
                                <li>YANGI KURSLARGA BEPUL YO’LLANMA</li>
                                <li>KUTUBXONA</li>
                            </ul>
                        </div>
                    </div>
                    <div className="landing_page_section_6_subscribe_cards_card_bottom">
                        <Link to="/register">
                            <button>OBUNA BO’LISH</button>
                        </Link>
                    </div>
                </AnimatedOnScroll>


            </div>

            <div className="landing_page_section_6_eVideos">
                <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                    <div className="landing_page_section_6_eVideos_text">
                        <h2>HALI HAM SHUBHANGIZ BO’LSA</h2>
                        <p>BU VIDEOLAR SIZ UCHUN</p>
                    </div>
                </AnimatedOnScroll>

                <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                    <div className="landing_page_section_6_eVideos_body">
                        <div>
                            {videoFeedback?.slice(0, visibleDivs)?.map((item, index) => (
                                <div key={index} className="landing_page_section_6_eVideos_body_card">
                                    {/*Content of Div {item}*/}
                                    <div className="landing_page_section_6_eVideos_body_card_left">
                                        <h2>{item?.name}</h2>
                                        <h3>{item?.author}</h3>
                                        <p>{item?.description}</p>
                                    </div>
                                    <div className="landing_page_section_6_eVideos_body_card_right">
                                        <ReactPlayer
                                            url={`${ip}/api/v2/video/stream/${item?.videoUrl}`}
                                            controls
                                        />
                                    </div>
                                </div>
                            ))}

                            <div className="landing_page_section_6_eVideos_body_button">
                                {visibleDivs < videoFeedback.length &&
                                <button onClick={handleLoadMore}>KO’PROQ YUKLASH</button>}
                                <div className="landing_page_section_6_eVideos_body_button_img"><img src={bottomVek}/>
                                </div>
                            </div>
                            <div className="landing_page_section_6_eVideos_body_line">

                            </div>
                        </div>
                    </div>
                </AnimatedOnScroll>
            </div>

            <div className="landing_page_section_7_note">
                <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                    <div className="landing_page_section_7_note_text">
                        <h2>BILASIZMI?</h2>
                        <p>ASLIDA 2 TURDAGI ODAMLAR MAVJUD</p>
                    </div>
                </AnimatedOnScroll>

                <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                    <div className="landing_page_section_7_note_cards">
                        <div className="landing_page_section_7_note_cards_card">
                            <div className="landing_page_section_7_note_cards_card_img">
                                <img src={eye}/>
                            </div>
                            <h2>KUZATUVCHILAR</h2>
                            <p>Shunday Insonlar borki, ular doimo Yangi va Qaynoq Imkoniyatlarni poylab yurishadi, o'sha
                                imkoniyatlar ularning oldidan chiqqanida esa, afsuski bu imkoniyatlardan foydalanmaslik
                                uchun har xil bahonalar topishadi. Masalan, PULIM YO'Q, VAQTIM YO'Q, SHAROITIM TO'G'RI
                                KELMAYDI va h.k
                                Va eng yakunida xuddi o'sha imkoniyatlardan boshqa insonlar foydalangan holda
                                Muvaffaqiyatga
                                erishgan paytda ular hamma narsadan quruq qolishgan bo'ladi!</p>
                        </div>
                        <div className="landing_page_section_7_note_cards_card">
                            <div className="landing_page_section_7_note_cards_card_img">
                                <img src={action}/>
                            </div>
                            <h2>HARAKAT QILUVCHILAR</h2>
                            <p>Bu toifadagi insonlar Imkoniyatlar bilan yuzma - yuz kelishganda, Mas'uliyatni qo'lga
                                olishadi va shaxsiy maqsadga erishish yo'lida olg'a qadam tashlashga QO'RQISHMAYDI.
                                To'g'ri,
                                ular uzoq vaqt harakat qilishlari, qiyinchiliklarga duch kelishlari mumkin , lekin ENG
                                KUCHLI Maqsadlariga yetishganlarida, o'z komfort zonalarini BUZIB CHIQQANLIKLARIDAN
                                rohatlanishadi.
                            </p>
                        </div>
                    </div>
                </AnimatedOnScroll>

                <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                    <div className="landing_page_section_7_note_bottom">
                        <h3>SIZ QAYSI YO’LNI TANLAYSIZ?<br/>
                            “HARAKAT QILUVCHILAR” POZITSIYASIDA ENG ZO’RI BO’LISHNIMI</h3>
                        <h2>YOKI</h2>
                        <h3>“KUZATUVCHI” POZITSIYASIDA QOLIB, 12 SOAT ISHLAB, O’YIN O’YNASHNI DAVOM ETTIRISHNIMI?</h3>
                    </div>

                    <div className="landing_page_section_7_note_line"></div>
                </AnimatedOnScroll>
            </div>

            <div className="landing_page_section_8_ferrari">
                <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                    <div className="landing_page_section_8_ferrari_top">
                        <h2>O’YLASHNING UMUMAN HOJATI QOLMADI</h2>
                        <h1>QIZIL FERRARI</h1>
                        <h2>UCHUN <span>KALITINGIZ</span> QARSHINGIZDA</h2>
                        <Link to="/register">
                            <div className="landing_page_section_8_ferrari_top_inner">KALITGA EGA BO’LING !</div>
                        </Link>
                        <h4>YOKI</h4>
                        <div className="landing_page_section_8_ferrari_top_inner2">HOZIRGI HAYOTINGIZNI DAVOM ETTIRING
                        </div>
                    </div>
                </AnimatedOnScroll>

                <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                    <div className="landing_page_section_6_subscribe_cards_title">
                        <h2>TANLOV FAQAT O’ZINGIZNING QO’LINGIZDA</h2>
                    </div>
                </AnimatedOnScroll>

                <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                    <div className="landing_page_section_6_subscribe_cards">
                        <div className="landing_page_section_6_subscribe_cards_card">
                            <div className="landing_page_section_6_subscribe_cards_card_title">
                                <h2>SHORT TERM</h2>
                            </div>
                            <div className="landing_page_section_6_subscribe_cards_card_price">
                                <span></span>
                                <h2>347.000</h2>
                            </div>
                            <ul>
                                <li>1 OYLIK OBUNA</li>
                                <li>O’ZLASHTIRISH 25%</li>
                                <li>UMUMIY KURSLARGA YO’LLANMA</li>
                                <li>ERKIN GRAFIKDA O’QISH</li>
                                <li>COMMUNITY CHAT</li>
                                <li>FOYDALI LINKLAR</li>
                                <li>QO’LLANMALAR</li>
                                <li>USER INTERFACE</li>
                                <li className="landing_page_section_6_subscribe_cards_card_price_redText">YANGI
                                    KURSLARGA BEPUL YO’LLANMA
                                </li>
                                <li className="landing_page_section_6_subscribe_cards_card_price_redText">KUTUBXONA</li>
                            </ul>
                        </div>
                        <div className="landing_page_section_6_subscribe_cards_card">
                            <div className="landing_page_section_6_subscribe_cards_card_title">
                                <h2>MEDIUM TERM</h2>
                            </div>
                            <div className="landing_page_section_6_subscribe_cards_card_price">
                                <span>15% chegirma</span>
                                <h2>880.000</h2>
                            </div>
                            <ul>
                                <li>3 OYLIK OBUNA</li>
                                <li>O’ZLASHTIRISH 50%</li>
                                <li>UMUMIY KURSLARGA YO’LLANMA</li>
                                <li>ERKIN GRAFIKDA O’QISH</li>
                                <li>COMMUNITY CHAT</li>
                                <li>FOYDALI LINKLAR</li>
                                <li>QO’LLANMALAR</li>
                                <li>USER INTERFACE</li>
                                <li className="">YANGI KURSLARGA BEPUL YO’LLANMA</li>
                                <li className="landing_page_section_6_subscribe_cards_card_price_redText">KUTUBXONA</li>
                            </ul>
                        </div>
                        <div className="landing_page_section_6_subscribe_cards_card">
                            <div className="landing_page_section_6_subscribe_cards_card_title">
                                <h2>LONG TERM</h2>
                            </div>
                            <div className="landing_page_section_6_subscribe_cards_card_price">
                                <span>25% chegirma</span>
                                <h2>1.560.000</h2>
                            </div>
                            <ul>
                                <li>1 OYLIK OBUNA</li>
                                <li>O’ZLASHTIRISH 75%</li>
                                <li>UMUMIY KURSLARGA YO’LLANMA</li>
                                <li>ERKIN GRAFIKDA O’QISH</li>
                                <li>COMMUNITY CHAT</li>
                                <li>FOYDALI LINKLAR</li>
                                <li>QO’LLANMALAR</li>
                                <li>USER INTERFACE</li>
                                <li className="">YANGI KURSLARGA BEPUL YO’LLANMA</li>
                                <li className="">KUTUBXONA</li>
                            </ul>
                        </div>
                    </div>

                    <div className="landing_page_section_8_ferrari_button">
                        <Link to="/register">
                            <button>OXIRGI IMKON !</button>
                        </Link>
                    </div>
                    <div className="landing_page_section_7_note_line"></div>
                </AnimatedOnScroll>

            </div>

            <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                <div className="landing_page_section_9_questions">
                    <div className="landing_page_section_9_questions_title">
                        <h1>SAVOLLAR</h1>
                    </div>
                    <div className="landing_page_section_9_questions_body">
                        <Collapse
                            items={[
                                {
                                    key: '1',
                                    label: 'Pro Digital qanday platforma?',
                                    children: <p>Pro Digital - Digital marketinga ixtisoslashgan, barcha yo'nalishdagi
                                        kurslar jamlangan platforma! Bu nafaqat ta'lim platforma, balki jamiyat
                                        hamdir.</p>,
                                },
                            ]}
                        />
                        <Collapse
                            items={[
                                {
                                    key: '1',
                                    label: 'Qanday a’zo bo’laman?',
                                    children: <p>Pro Digital - Digital marketinga ixtisoslashgan, barcha yo'nalishdagi
                                        kurslar jamlangan platforma! Bu nafaqat ta'lim platforma, balki jamiyat
                                        hamdir.</p>,
                                },
                            ]}
                        />
                    </div>
                </div>
            </AnimatedOnScroll>


            <div className="landing_page_section_footer" id="aloqa">
                <AnimatedOnScroll animationIn="fadeInUp" animationInDelay="50" animationInDuration="500">
                    <div className="landing_page_section_footer_inner">
                        <div className="landing_page_section_footer_inner_logo">
                            <a href="#"><img src={footerLogo}/></a>
                        </div>
                        <div className="landing_page_section_footer_inner_links">
                            <div className="landing_page_section_footer_inner_links_line"></div>
                            <ul>
                                <li><a href="#">BIZ HAQIMIZDA</a></li>
                                <li><a href="#kurslar">KURSLAR</a></li>
                                <li><a href="#tarif">TA’RIFLAR</a></li>
                                <li><a href="#aloqa">ALOQA</a></li>
                            </ul>
                        </div>
                        <div className="landing_page_section_footer_inner_links">
                            <div className="landing_page_section_footer_inner_links_line"></div>
                            <ul>
                                <li><a>POCHTA</a></li>
                                <li><a>+998 99 827 45 25</a></li>
                                <li><a>OFERTA</a></li>
                                <li><a>GUVOHNOMA</a></li>
                            </ul>
                        </div>
                        <div className="landing_page_section_footer_inner_links">
                            <div className="landing_page_section_footer_inner_links_line"></div>
                            <div>
                                <ul>
                                    <li><a>IJTIMOIY TARMOQLAR</a></li>
                                </ul>
                                <div className="landing_page_section_footer_inner_links_messenger">
                                    <a href={`https://www.facebook.com/prodigitalcourse.org`} target="_blank"><img
                                        src={facebook}/></a>
                                    <a href={`https://www.youtube.com/@prodigitaluz`} target="_blank"><img
                                        src={youtube}/></a>
                                    <a href={`https://www.instagram.com/prodigitaluzb/`} target="_blank"><img
                                        src={instagram}/></a>
                                    <a href={`https://t.me/prodigitaluz`} target="_blank"><img src={telegram}/></a>
                                </div>
                                <p>BIZNI KUZATIB BORING</p>
                            </div>
                        </div>
                    </div>
                </AnimatedOnScroll>
                <div className="landing_page_section_footer_line"></div>

                <div className="landing_page_section_footer_bottom">
                    <p>
                        2024 © REVX AGENCY - BARCHA HUQUQLAR HIMOYALANGAN - INN: 308542122 - GUVOHNOMA RAQAMI:
                        8798465222
                    </p>
                </div>
            </div>

        </div>

    );
};

export default LandingPage;