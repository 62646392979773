import React, {useEffect, useState} from 'react';


import './library.css';
import searchIcon from '../../../../images/user/userDashboard/searchIcon.png';
import LibraryTable from "./LibraryTable";
import LibraryPagination from "./LibraryPagination";
import {storage} from "../../../../services";
import axios from "axios";
import {ip} from "../../../../ip";

const Library = () => {

    const token = storage.local.get("token");

    const [libraryTotal, setlibraryTotal] = useState(null);
    const [libraryPaginationLimit, setlibraryPaginationLimit] = useState(7);
    const [libraryPaginationCurrent, setlibraryPaginationCurrent] = useState(1);

    const libraryPaginationOnchange = (e = 1, option) => {
        setlibraryPaginationCurrent(e)
        setlibraryPaginationLimit(option)
    }


    const [libraryData, setLibraryData] = useState([]);

    useEffect(() => {
        axios.get(`${ip}/api/v1/library/getAllBook`,
            {headers: {'Authorization': `Bearer ${token}`}}
        )
            .then((res) => {
                const newData = res?.data?.item?.map((item, index) => (
                    {
                        ...item,
                        key: index + 1,
                        name : item.name,
                        author : item.author,
                        subject : item.subject,
                        type : item.type,
                        fileId : item.fileId,
                        id : item.id,
                        premium : item.premium
                    }
                ))
                setLibraryData(newData);
                // setFilteredData(res?.data?.item);
            });
    }, []);

    // search
    const [query, setQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const filterData = (searchQuery) => {
        if (!searchQuery) {
            setFilteredData(libraryData);
            return;
        }
        const filtered = libraryData.filter((item) =>
            item?.name?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
            item?.author?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
            item?.subject?.toLowerCase()?.includes(searchQuery.toLowerCase())
        );
        setFilteredData(filtered);
    };
    const handleInputChange = (e) => {
        setQuery(e.target.value);
        filterData(e.target.value);
    };
    useEffect(() => {
        filterData(query);
    }, [libraryData, query]);

    // search
    return (
        <div className="library">
            <div className="library_header">
                <h2>KUTUBXONA</h2>
            </div>
            <div className="library_body">
                <div className="library_body_table">
                    <div className="library_body_search">
                        <img src={searchIcon}/>
                        <input
                            type="text"
                            value={query}
                            onChange={handleInputChange}
                            placeholder="Qidiruv..."
                        />
                    </div>
                    <LibraryTable
                        data={filteredData}
                    />
                </div>
            </div>
        </div>
    );
};

export default Library;