import React from 'react';
import { Space, Table, Tag } from 'antd';


import fileIcon from '../../../../images/user/userDashboard/fileicon.png';
import './library.css';

import styled from 'styled-components';
import {ip} from "../../../../ip";


export const TableStyles = styled(Table)`
  tbody {
    background: ${({theme}) => theme.body};
    color: ${({theme}) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }
  thead tr th {
    background: ${({theme}) => theme.body};
    color: ${({theme}) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: ${({theme}) => theme.trhover};
  }
`;

const LibraryTable = (props) => {

    const {
        data,
        rowSelection
    } = props;

    const columns = [
        {
            title: 'T/R',
            dataIndex: 'key',
            // align: 'center'
            // width : 50,
        },
        {
            title: "NOM",
            dataIndex: 'name',
            key: 'name',
            textAlign : 'center',
            render: (text , record) => <div className="library_body_table_text">{record.name}</div>,
        },
        {
            title: 'MUALLIF',
            dataIndex: 'MUALLIF',
            key: 'MUALLIF',
            textAlign : 'center',
            render: (text , record) => <div className="library_body_table_text">{record.author}</div>,
        },
        {
            title: 'YO’NALISH',
            dataIndex: 'address',
            key: 'address',
            textAlign : 'center',
            render: (text , record) => <div className="library_body_table_text">{record.subject}</div>,
        },
        {
            title: 'TURI',
            dataIndex: 'SUMMA',
            key: 'address',
            textAlign : 'center',
            render: (text , record) => <div className="library_body_table_text">{record.type}</div>,
        },
        {
            title: <img src={fileIcon}/>,
            dataIndex: 'age',
            key: 'age',
            textAlign : 'center',
            width : 150,
            render: (text , record) => <div className={"library_body_table_text library_body_table_text_pay"}>
                <a href={
                    record?.premium === true && record?.fileId !==null ?
                        `${ip}/api/file/download/${record?.fileId}`
                        : "#"
                }
                    target={record?.premium === true && record?.fileId !==null  ? "_blank" : ""}
                   style={{textDecoration : "none" , color: "white"}}>{"YUKLASH"}</a>
            </div>,
        },
    ];

    return (
        <div>
            <TableStyles
                columns={columns}
                dataSource={data}
                pagination={false}
            />
        </div>
    );
};

export default LibraryTable;