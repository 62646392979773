import React, {useEffect, useState} from 'react';

import {DatePicker, Form, Input, message, Radio, Select} from "antd";
import axios from "axios";

import './loginPage.css';
import {ip} from "../../ip";
import {Link} from "react-router-dom";
import {LoginSuccess} from "../../redux/action/action";
import {useDispatch} from "react-redux";
import Loader from "../loading/Loader";
import {IoChevronBackCircle} from "react-icons/io5";
import logo from '../../images/user/icon copy (3) 2.png';

const Login = () => {

        const dispatch = useDispatch();

        const [loading, setLoading] = useState(false);

        const initialValues = {
            login: '',
            password: ''
        }

        const onFinish = (values) => {
            setLoading(true)
            // dispatch(LoginSuccess({name : "salom" , username : "eldor"}));
            axios.post(`${ip}/api/v1/auth/login`, {
                username: values.username,
                password: values.password
            })
                .then(res => {
                    message.success("Xush kelibsiz !");
                    dispatch(LoginSuccess(res?.data?.item));
                    setLoading(false);
                    // localStorage.setItem('keys', JSON.stringify(['sub1']));
                    // localStorage.setItem('selected-id', JSON.stringify(['2']));
                })
                .catch(err => {
                    message.error(err?.response?.data?.message);
                    setLoading(false);
                });
        }
        const onFinishFailed = (e) => {
            // console.log(e)
        }


            return (
                <div className="login">
                    {
                        loading && <Loader/>
                    }
                    <div className="login_inner">
                        <div className="login_inner_left">
                            <div className="login_inner_left_back"><Link to="/"><IoChevronBackCircle /></Link></div>
                            {/*<div className="login_inner_left_back"><Link to="/"><img src={logo}/></Link></div>*/}

                            <div className="login_inner_left_menu">
                                <div
                                    className={window.location.pathname === "/login" ? 'login_inner_left_menu_inner active' : 'login_inner_left_menu_inner'}>
                                    <Link to="/login">KIRISH</Link>
                                </div>
                                <div
                                    className={window.location.pathname === "/register" ? 'login_inner_left_menu_inner active' : 'login_inner_left_menu_inner'}>
                                    <Link to="/register">RO’YXATDAN O’TISH</Link>
                                </div>
                            </div>

                            <div className="login_inner_left_form">
                                <div className="login_inner_left_form_inner">
                                    <Form
                                        name="basic"
                                        layout="vertical"
                                        requiredMark='optional'
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                        autoComplete="off"
                                        initialValues={initialValues}
                                    >
                                        <div className="login_inner_left_form_field">
                                            <Form.Item name="username" rules={
                                                [{
                                                    required: true,
                                                    message: "Email pochtani kiriting"
                                                }]
                                            }>
                                                <Input placeholder="Email pochta"/>
                                            </Form.Item>
                                        </div>
                                        <div className="login_inner_left_form_field">
                                            <Form.Item name="password" rules={
                                                [{
                                                    required: true,
                                                    message: "Parolni kiriting"
                                                }]
                                            }>
                                                <Input.Password placeholder="Parol"/>
                                            </Form.Item>
                                        </div>
                                        <div className="login_inner_left_form_button">
                                            <button type="submit">KIRISH</button>
                                        </div>
                                        <p>Parolni unutdingizmi?</p>
                                    </Form>
                                </div>

                            </div>
                        </div>
                        <div className="login_inner_right">
                            <div className="login_inner_right_inner">
                                <h2>TIZIMGA KIRISH UCHUN QO’LLANMA</h2>
                                <div className="login_inner_right_video">

                                </div>
                                <div className="login_inner_right_inner_text">
                                    <h1>ESLATMA !</h1>
                                    <p>Platformadan foydalanish uchun tizimda shaxsiy akkauntingiz bo’lishi kerak</p>
                                    <p>Siz platformaga endi tashrif buyurgan bo’lsangiz <h2>“RO’YXATDAN O’TISH”</h2>
                                        orqali shaxsiy akkaunt ochasiz
                                    </p>
                                    <p>Agar ro’yxatdan o’tgan bo’lsangiz <h2>“KIRISH”</h2> orqali shaxsiy akkauntingizga
                                        kirasiz
                                    </p>
                                    <p>Ro’yxatdan o’tganingizdan so’ng, siz kiritgan email pochtaga login va parolingiz
                                        boradi.
                                        Agar
                                        kelmasa iltimos SPAMni tekshiring</p>
                                    <p>Kurslarni shaxsiy akkauntingizda mavjud bo’lgan <h2>“BARCHA KURSLAR”</h2> bo’limidan
                                        xarid
                                        qilasiz !
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
    }
;

export default Login;